.admin-upload-container {
   /* max-width: 1200px;*/
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .upload-section {
    background-color: #f0f0f0;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
  }
  
  .upload-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .upload-form input,
  .upload-form textarea {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .upload-form button {
    padding: 10px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .upload-form button:hover {
    background-color: #45a049;
  }
  
  .progress-bar {
    width: 100%;
    background-color: #ddd;
    border-radius: 4px;
    margin-top: 10px;
  }
  
  .progress-bar-fill {
    height: 20px;
    background-color: #4CAF50;
    border-radius: 4px;
    text-align: center;
    line-height: 20px;
    color: white;
  }
  
  .pdf-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .pdf-table th,
  .pdf-table td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: left;
  }
  
  .pdf-table th {
    background-color: #f2f2f2;
    font-weight: bold;
  }
  
  .pdf-table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .pdf-table tr:hover {
    background-color: #f5f5f5;
  }
  
  .status-indicator {
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 5px;
  }
  
  .status-active {
    background-color: green;
  }
  
  .status-inactive {
    background-color: transparent;
    border: 1px solid black;
  }
  
  .action-buttons {
    display: flex;
    gap: 5px;
  }
  
  .action-buttons button {
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .delete-button {
    background-color: #970a00;
    color: white;
  }
  
  .detail-button {
    background-color: #008CBA;
    color: white;
  }
  
  .detail-table {
    margin-top: 10px;
    width: 100%;
    border-collapse: collapse;
  }
  
  .detail-table th,
  .detail-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .detail-table th {
    background-color: #f2f2f2;
    font-weight: bold;
  }

  .resubmit-button {
    background-color: #ffa500;
    color: white;
  }

  .delete-record-button {
    background-color: #ff0000;
    color: white;
  }

  .general-files-section {
    margin-top: 20px;
  }
  
  .dropdown {
    position: relative;
    display: inline-block;
    margin-right: 10px;
  }
  
  .dropbtn {
    background-color: #4CAF50;
    color: white;
    padding: 10px;
    font-size: 16px;
    border: none;
    cursor: pointer;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  
  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  .dropdown-content a:hover {background-color: #f1f1f1}
  
  .dropdown:hover .dropdown-content {
    display: block;
  }
  
  .dropdown:hover .dropbtn {
    background-color: #3e8e41;
  }
  
  .file-selection-row {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;
  }
  
  .custom-file-input {
    flex: 0 0 auto;
  }
  
  .general-files-section {
    flex: 1;
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .general-files-section label {
    margin-bottom: 0;
    white-space: nowrap;
  }
  
  .btn-group {
    display: flex;
  }
  
  .btn-group .btn {
    flex: 1;
  }

  
  /* ... (mantener otros estilos existentes) */
  
  .file-selection-container .input-group {
    flex-wrap: nowrap;
  }
  
  .file-selection-container .custom-file {
    flex: 0 0 auto;
    width: auto;
  }
  
  .file-selection-container .btn-group {
    margin-left: 5px;
  }
  
  .custom-file-input:lang(en)~.custom-file-label::after {
    content: "Buscar";
  }
  
  /* ... (mantener otros estilos existentes) */

  /* Login.css */
.login-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  background: #f4f4f4;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.login-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.login-form input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.login-form button {
  padding: -10px, 10px;
  /*background-color: #4CAF50;*/
  transform: translateX(-100%) translateY(-55%);
  color: rgb(95, 95, 95);
  border: none;
  border-radius: 0px;
  cursor: pointer;
}

.login-form button:hover {
  background-color: #45a049;
}

.error {
  color: red;
}

.copy-button {
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  margin-left: 5px;
  transition: opacity 0.3s;
  opacity: 0.7;
}

.copy-button:hover {
  opacity: 1;
}

/* Estilos actualizados para el login */
.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f5f5;
}

.login-container {
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 300px;
  text-align: center;
}

.lock-icon {
  width: 60px;
  height: 60px;
  margin: 0 auto 20px;
}

.login-form {
  display: flex;
  flex-direction: column;
}

.input-container {
  position: relative;
  display: flex;
  align-items: center;
}

.password-input {
  width: calc(100%);
  padding: 10px 30px 10px 0;
  border: none;
  border-bottom: 1px solid #ddd;
  font-size: 16px;
  outline: none;
}

.submit-arrow {
  position: absolute;
  right: 0;
  top: 50%;
  background: none;
  border: none;
  color: #999;
  font-size: 24px;
  cursor: pointer;
  padding: 0;
}

.error {
  color: #ff0000;
  margin-top: 10px;
  font-size: 14px;
}
